//some elements variables
@blockquote-border: #E5EAF1;

@pagination-color: #2283C5;
@pagination-border: darken(#E0E8EB, 4%);
@pagination-bg: #FAFAFA;
@pagination-bg-hover: #EAEFF2;

@pagination-bg-disabled: #F9F9F9;
@pagination-border-disabled: #D9D9D9;

@pagination-color-active: #FFF;
@pagination-bg-active: #6FAED9;
@pagination-border-active: #6FAED9;

//elements
.@{icon} {
  text-align: center;
}

a {
  &:focus, &:active {
    text-decoration: none;
  }
}

//header sizes
.h-size(@index) when (@index > 0) {
  @h-tag : ~`"h@{index}"`;
  @{h-tag} {
    @tmpvar : ~`"h@{index}-size"`; //get the variable h1-size, h2-size , etc...
    font-size: unit(@@tmpvar , px);
    font-weight: normal;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

    &.smaller {
      font-size: unit((@@tmpvar - 1) , px);
    }
    &.bigger {
      font-size: unit((@@tmpvar + 1) , px);
    }
    &.block {
      margin-bottom: 16px;
    }
  }
}

.h-size(1);
.h-size(2);
.h-size(3);
.h-size(4);
.h-size(5);
.h-size(6);

//some list styling
ul, ol {
  //split margins for better RTL side
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 0;
  margin-left: 25px;

  padding: 0;

  &.margin-5 {
    margin-left: 5px;
  }
  &.margin-10 {
    margin-left: 10px;
  }
  &.margin-15 {
    margin-left: 15px;
  }
  &.margin-20 {
    margin-left: 20px;
  }
}

li > ul,
li > ol {
  margin-left: 18px;
}

.list-unstyled, .list-inline {
  margin-left: 0;

  > li > .@{icon}:first-child {
    width: 18px;
    text-align: center;
  }
}

.spaced > li {
  margin-top: 9px;
  margin-bottom: 9px;
}

.spaced2 > li {
  margin-top: 15px;
  margin-bottom: 15px;
}

li.divider {
  margin-top: 3px;
  margin-bottom: 3px;
  height: 0;
  font-size: 0;

  .spaced > & {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .spaced2 > & {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &:before {
    content: "";
    display: inline-block;
  }
}

li.spacer {
  margin-bottom: 20px !important;
  height: 0;
  font-size: 0;
}

//little elements
blockquote, blockquote.pull-right, .blockquote-reverse {
  border-color: @blockquote-border;
}

pre.prettyprint {
  white-space: pre-wrap;
}

//wells
.well {
  .border-radius(0);
}

.well h1, .well h2, .well h3, .well h4, .well h5, .well h6 {
  margin-top: 0;
}

.well h1, .well h2, .well h3 {
  line-height: 36px;
}

//alerts
.alert {
  font-size: @font-size-alert;
  .border-radius(0);

  .close {
    font-size: @font-size-alert-close;
  }
}

.alert-block p + p {
  margin-top: 10px;
}

//pagination
.enable_pagination() when (@enable-pagination = true) {
  .pagination > li > span, .pager > li > span {
    border-width: 1px;
    border-radius: 0 !important;
  }
  .pagination > li > a, .pager > li > a {
    border-width: 1px;
    border-color: @pagination-border;
    border-radius: 0 !important;

    color: @pagination-color;
    background-color: @pagination-bg;
    margin: 0 -1px 0 0;
    position: relative;
    z-index: auto;

    &:hover {
      color: darken(@pagination-color, 8%);

      background-color: @pagination-bg-hover;
      border-color: darken(@pagination-border , 6%);
      z-index: 1;
    }
    &:focus {
      background-color: spin(@pagination-bg-hover, 10%);
    }
  }

  .pagination > li.disabled > a, .pager > li.disabled > a {
    &, &:hover {
      background-color: @pagination-bg-disabled;
      border-color: @pagination-border-disabled;
      z-index: auto;
    }
  }

  .pagination > li.active > a {
    &, &:hover {
      background-color: @pagination-bg-active;
      border-color: @pagination-border-active;
      color: @pagination-color-active;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      z-index: 2;
    }
  }
}

.enable_pagination();

//list group
.list-group {
  margin-left: auto;
}

.list-group-item {
  border-radius: 0 !important;
}
