// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
  margin-right: auto;
  margin-left: auto;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);
  &:extend(.clearfix all);
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
  margin-left:  (@gutter / -2);
  margin-right: (@gutter / -2);
  &:extend(.clearfix all);
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  float: left;
  width: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  min-height: 1px;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);
}
.make-xs-column-offset(@columns) {
  margin-left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
}
.make-xs-column-push(@columns) {
  left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
}
.make-xs-column-pull(@columns) {
  right: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);

  @media (min-width: @screen-sm-min) {
    float: left;
    width: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-sm-column-offset(@columns) {
  @media (min-width: @screen-sm-min) {
    margin-left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-sm-column-push(@columns) {
  @media (min-width: @screen-sm-min) {
    left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-sm-column-pull(@columns) {
  @media (min-width: @screen-sm-min) {
    right: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);

  @media (min-width: @screen-md-min) {
    float: left;
    width: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-md-column-offset(@columns) {
  @media (min-width: @screen-md-min) {
    margin-left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-md-column-push(@columns) {
  @media (min-width: @screen-md-min) {
    left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-md-column-pull(@columns) {
  @media (min-width: @screen-md-min) {
    right: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);

  @media (min-width: @screen-lg-min) {
    float: left;
    width: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-lg-column-offset(@columns) {
  @media (min-width: @screen-lg-min) {
    margin-left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-lg-column-push(@columns) {
  @media (min-width: @screen-lg-min) {
    left: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
.make-lg-column-pull(@columns) {
  @media (min-width: @screen-lg-min) {
    right: ~`(""+((@{columns} / @{grid-columns}) * 100)).substring(0, (@{columns} / @{grid-columns} < 0.1 ? 5 : 6))+"%"`;
  }
}
