.add-element, .add-element:hover {
  border: 2px dashed #D9D9D9;
  padding: 10px;
  text-transform: uppercase;
  color: #999999;
  text-decoration: none;
  cursor: pointer;
  display: block;
}

.add-element:hover {
  border: 2px dashed #d9534f;
}