//tooltips and popovers

.enable_tooltips() when (@enable-tooltips = true) {

  .tooltip.in {
    .opacity(1);
  }

  .tooltip-inner {
    background-color: @tooltip-color;
    color: #FFF;
    font-size: @font-size-tooltip;
    //white-space: nowrap;

    text-shadow: 1px 1px 0 rgba(42, 45, 50, 0.5);
    .border-radius(0);

    padding: 5px 9px;
  }

  .tooltip.top .tooltip-arrow {
    border-bottom-width: 0;
    border-top-color: @tooltip-color;
    -moz-border-top-colors: @tooltip-color;
  }
  .tooltip.right .tooltip-arrow {
    border-left-width: 0;
    border-right-color: @tooltip-color;
    -moz-border-right-colors: @tooltip-color;
  }
  .tooltip.left .tooltip-arrow {
    border-right-width: 0;
    border-left-color: @tooltip-color;
    -moz-border-left-colors: @tooltip-color;
  }
  .tooltip.bottom .tooltip-arrow {
    border-top-width: 0;
    border-bottom-color: @tooltip-color;
    -moz-border-bottom-colors: @tooltip-color;
  }

  .tooltip(@color , @shadow) {
    @tooltip_name : ~`".tooltip-@{color}"`;
    @tooltip_color : ~`"tooltip-@{color}-color"`;

    @{tooltip_name} + .tooltip, .tooltip@{tooltip_name} {
      > .tooltip-inner {
        background-color: @@tooltip_color;
        text-shadow: @shadow;
      }
      &.top .tooltip-arrow {
        border-top-color: @@tooltip_color;
        -moz-border-top-colors: @@tooltip_color;
      }
      &.right .tooltip-arrow {
        border-right-color: @@tooltip_color;
        -moz-border-right-colors: @@tooltip_color;
      }
      &.left .tooltip-arrow {
        border-left-color: @@tooltip_color;
        -moz-border-left-colors: @@tooltip_color;
      }
      &.bottom .tooltip-arrow {
        border-bottom-color: @@tooltip_color;
        -moz-border-bottom-colors: @@tooltip_color;
      }
    }
  }

  .tooltip(error, ~"1px 1px 0 rgba(100,60,20,0.3)");
  .tooltip(success, ~"1px 1px 0 rgba(60,100,20,0.3)");
  .tooltip(warning, ~"1px 1px 0 rgba(100,90,10,0.3)");
  .tooltip(info, ~"1px 1px 0 rgba(40,50,100,0.3)");

}

.enable_tooltips();

.enable_popovers() when (@enable-popovers = true) {
  @popover-color: #EFF3F8;
  @popover-error-color: #F7F0EF;
  @popover-warning-color: #F4EEE3;
  @popover-success-color: #E8F2E3;
  @popover-info-color: #E5EDF8;

  /* popover */
  .popover {
    .border-radius(0);
    padding: 0;
    border-color: #ccc;
    border-width: 1px;

    .box-shadow(~"0 0 4px 2px rgba(0,0,0,0.2)");
    color: #4D6883;
  }
  .popover-title {
    border-radius: 0;
    background-color: @popover-color;
    color: #555;
    border-bottom: 1px solid darken(@popover-color, 5%);

    text-shadow: 1px 1px 1px rgba(220, 220, 220, 0.2);
  }
  .popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;

    border-bottom-color: @popover-color;
    -moz-border-bottom-colors: @popover-color;

    border-top-width: 0;
  }
  .popover.left > .arrow:after {
    -moz-border-left-colors: #FFF;
  }
  .popover.right > .arrow:after {
    -moz-border-right-colors: #FFF;
  }
  .popover.top > .arrow:after {
    -moz-border-top-colors: #FFF;
  }

  .popover(@color , @text-color) {
    @popover_name : ~`".popover-@{color}"`;
    @popover_color : ~`"popover-@{color}-color"`;

    @{popover_name} + .popover, .popover@{popover_name} {
      border-color: @@popover_color;
      .popover-title {
        background-color: @@popover_color;
        border-bottom-color: darken(@@popover_color, 5%);
        color: @text-color;
        text-shadow: none;
      }

      &.top .arrow:after {
        border-top-color: @@popover_color;
        -moz-border-top-colors: @@popover_color;
      }
      &.bottom .arrow:after {
        border-bottom-color: @@popover_color;
        -moz-border-bottom-colors: @@popover_color;
      }
      &.right .arrow:after {
        border-right-color: @@popover_color;
        -moz-border-right-colors: @@popover_color;
      }
      &.left .arrow:after {
        border-left-color: @@popover_color;
        -moz-border-left-colors: @@popover_color;
      }

    }
  }

  .popover(error, #B75445);
  .popover(warning, #D67E31);
  .popover(success, @tooltip-success-color);
  .popover(info, #3F79B6);

  .popover-notitle + .popover, .popover.popover-notitle {
    .popover-title {
      display: none;
    }
    &.top .arrow:after {
      border-top-color: #FFF;
      -moz-border-top-colors: #FFF;
    }
    &.bottom .arrow:after {
      border-bottom-color: #FFF;
      -moz-border-bottom-colors: #FFF;
    }
    &.left .arrow:after {
      border-left-color: #FFF;
      -moz-border-left-colors: #FFF;
    }
    &.right .arrow:after {
      border-right-color: #FFF;
      -moz-border-right-colors: #FFF;
    }
  }

}

.enable_popovers();

#debugbar {
  .popover {
    width: 800px;
    max-width: none;
  }

  a {
    cursor: pointer;
  }
}

#debugbar .popover-content {
  height: 600px;
  overflow-y: scroll;
}