.chosen-container + .help-inline {
 vertical-align:middle;
}

//chosen select boxes -- replace icons with FontAwesome
/**
.chosen-select {
  display: inline !important; //for validation plugin to work it must be displayed
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-width: 0;
}
*/
.chosen-container , [class*="chosen-container"]{
  vertical-align: middle;
  > .chosen-single {
	line-height: 28px;
	height: 32px;
	box-shadow: none;
	background: #FAFAFA;
  }
}

.chosen-choices {
  box-shadow:none !important;
}
.chosen-container-single .chosen-single abbr {
  background: none;
}
.chosen-container-single .chosen-single abbr:after {
  content:"\f00d";
  display: inline-block;
  color:#888;
  font-family: FontAwesome;
  font-size: @base-font-size;

  position: absolute;
  right: 0;
  top: -7px;
}
.chosen-container-single .chosen-single abbr:hover:after {
  color:#464646;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover:after {
  color:#464646;
}


.chosen-single div b {
	 background: none !important;
	 &:before {
		content: "\f0d7";
		display: inline-block;
	  
		color: #888;
		font-family: FontAwesome;
		font-size: @base-font-size - 1;
	  
		position: relative;
		top: -1px;
		left: 1px;
	 }
}
.chosen-container-active.chosen-with-drop .chosen-single div b:before {
	content:"\f0d8";
}


.chosen-container-single {
  .chosen-search {
	 position:relative;
	 input[type="text"] {
		background:none;
		border-radius:0;
		line-height:28px;
		height:28px;
	 }
	 &:after{
		content:"\f002";
		display:inline-block;
		  
		color: #888;
		font-family: FontAwesome;
		font-size: @base-font-size + 1;
		  
		position:absolute;
		top:8px;
		right:12px;
	}
  }
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
	height:25px;
}
.chosen-container-multi .chosen-choices li.search-choice {
	line-height: 16px;
	padding-bottom: 4px;
}



.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
   background:none;

  &:before {
	  content:"\f00d";
	  display: inline-block;
	  color:#888;
	  font-family:FontAwesome;
	  font-size: @base-font-size;
	 
	  position: absolute;
	  right: 2px;
	  top: -1px;
  }
  &:hover {
	text-decoration:none;
  }
  &:hover:before {
	color:#464646;
  }
}
.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close:before {
  color:#464646;
}


.chosen-container .chosen-results-scroll-down ,
.chosen-container .chosen-results-scroll-up {
  span {
	background: none;
	&:before{
	  content: "\f0d7";
	  display: inline-block;
	  
	  color: #888;
	  font-family: FontAwesome;
	  font-size: @base-font-size - 1;

	  position: relative;
	  top: -1px;
	  left: 1px;
	}
  }
  
}
.chosen-container .chosen-results-scroll-up span:before {
  content:"\f0d8";
}
.chosen-container-active .chosen-single-with-drop div b:before {
  content:"\f0d8";
}


.chosen-rtl .chosen-search {
  input[type="text"] {
	background: none;
  }
  &:after {
	content:"";
	display:none;
 }
 &:before {
	content: "\f002";
	display: inline-block;
  
	color: #888;
	font-family: FontAwesome;
	font-size: @base-font-size + 1;
  
	position: absolute;
	top: 9px;
	left: 12px;
 }
}



/** chosen - etc */
.chosen-container-single .chosen-single {
  border-radius:0;
}
.chosen-container .chosen-results li.highlighted {
 // background:#86BD6F;/* green */
  //background:#4492C9;/* blue1 */
  background:#316AC5;
  color: #FFF;
}
.chosen-container-single .chosen-drop {
  border-radius:0;
  border-bottom:3px solid #4492C9;
  border-color:#4492C9;
}
.chosen-single.chosen-single-with-drop  , .chosen-container-active .chosen-single{
  border-color:#4492C9;
}

.chosen-single {
	.form-group.has-error & {
		border-color:@error-state-border !important;
	}
	.form-group.has-info & {
		border-color:@info-state-border !important;
	}
	.form-group.has-warning & {
		border-color:@warning-state-border !important;
	}
	.form-group.has-success & {
		border-color:@success-state-border !important;
	}
}

//.chosen-rtl .chosen-container-single-nosearch .chosen-search { left: -9999px; }
//.chosen-rtl .chosen-drop { left: -9999px; }

//     .modal .chosen-rtl .chosen-container-single-nosearch .chosen-search { left: 9999px; }
//		.modal .chosen-rtl .chosen-drop { left: 9999px; }
//.rtl .modal .chosen-container.chosen-with-drop .chosen-drop { left: auto; right: 0; }



.chosen-container-active.chosen-with-drop .chosen-single {
	border-color:#4492C9;
}


.chosen-container .chosen-drop {
 display: none;
}
.chosen-container.chosen-with-drop .chosen-drop {
 left: auto;
 right: auto;
 display: block;
}



@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi)  {
  .chosen-rtl .chosen-search input[type="text"], .chosen-container-single .chosen-single abbr, .chosen-container-single .chosen-single div b, .chosen-container-single .chosen-search input[type="text"], .chosen-container-multi .chosen-choices li.search-choice .search-choice-close, .chosen-container .chosen-results-scroll-down span, .chosen-container .chosen-results-scroll-up span {
      background-image: none !important;
      background-repeat: no-repeat !important;
      background-size: auto !important;
  }
}








//a second style (like tag inpit)
.enable_plugin_chosen_style_2() when(@enable-plugin-chosen-style-2 = true) {
.tag-input-style + .chosen-container-multi {
	.chosen-choices li.search-choice {
		background-image:none;
		background-color:@tag-bg;
		color: #FFFFFF;
		display: inline-block;
		font-size: @base-font-size;
		font-weight: normal;
		margin-bottom: 3px;
		margin-right: 3px;
		padding: 6px 22px 7px 9px;
		position: relative;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
		transition: all 0.2s ease 0s;
		vertical-align: baseline;
		white-space: nowrap;
		
		border:none;
		.box-shadow(none);
		.border-radius(0);
		
		.search-choice-close {
			position:absolute;
			top:0; bottom:0;
			right:0;
			width:18px;
			height:auto;
			line-height:25px;
			text-align:center;
			
			&:before {
				color:#FFF;
				position:static;
				font-size: @base-font-size - 2;
			}
			
			&:hover {
				background-color:rgba(0,0,0,0.2);
				&:before {
					color:#FFF;
				}
			}
		}
	}
}
.tag-input-style + .chosen-container-multi.chosen-rtl {
	.chosen-choices li.search-choice {
		padding: 6px 9px 7px 22px;
		margin-left: 0;
		margin-right:3px !important;//to override .RTL's

		.search-choice-close {
			right:auto;
			left:0;
		}
	}
}
}